<template>
     <div>
       <Collapse v-model="value">
        <Panel name="1">
            申请人基本资料
            <p slot="content">
                <Table :columns="columns1" border :show-header="false" :data="table1" disabled-hover  :span-method="handleSpan"></Table>
            </p>
        </Panel>
        <Panel name="2">
            申请服务类别及申请人现状
            <p slot="content">
                <Table :columns="columns2" border :show-header="false" :data="table2" disabled-hover  :span-method="handleSpan2"></Table>
            </p>
        </Panel>
        <Panel name="3">
            相关证件
            <p slot="content">
                <Table :columns="columns3" border :show-header="false" :data="table3" disabled-hover  :span-method="handleSpan3"></Table>
            </p>
        </Panel>
     </Collapse>
     <Modal
        v-model="promiseModal"
        title="承诺保证"
        :loading="true"
        @on-ok="save">
        <p>本人承诺：以上所填写的资料真实、有效、完备。如有弄虚作假、骗取资助行为的，接受取消资助资格，退回资助经费的处理并承担相关法律责任。</p>
    </Modal>
    <img src="" ref="imageTest" alt="">
     <!-- 预览模板 -->
      <LiefengModal
        :title="idNumTitle"
        :value="idNumStatus"
        @input="(status) => {idNumStatus = status}"
        width="500px"
        height="calc(100vh - 200px)"
      >
        <template v-slot:contentarea>
          <div style="text-align:center">
            <img :src="tempImgUrl1" alt="">
          <img :src="tempImgUrl2" alt="">
          </div>
        </template>
      </LiefengModal>

       <!-- 查看证件 -->
      <LiefengModal
      title="预览证件"
        :value="certificateStatus"
        @input="(status) => {certificateStatus = status}"
        width="500px"
      >
        <template v-slot:contentarea>
          <div style="text-align:center">
            <img :src="certificateUrl1" alt="" style="width: 100%;height: 45%;margin-bottom: 10px">
          </div>
        </template>
      </LiefengModal>
     </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      value: ["1", "2", "3"],
      promiseModal: false,
      columns1: [
        {
          key: "title1",
          align: "center",
          width: 200,
        },
        {
          key: "name1",
          render: (h, params) => {
            return h("Input", {
              props: {
                value: params.row.name1,
                maxlength: 18,
              },
              on: {
                "on-change": (e) => {
                  this.table1[params.index].name1 = e.target.value.trim();

                  if (params.row.type == "age" && params.index == 1) {
                    this.table1[params.index].name2 = "";
                    this.table1[0].name3 = "";
                    if (e.target.value.length == 18) {
                      this.table1[params.index].name2 = this.getAnalysisIdCard(
                        e.target.value,
                        2
                      );
                      this.table1[0].name3 = this.getAnalysisIdCard(
                        e.target.value,
                        1
                      );
                    }
                  }
                },
              },
            });
          },
        },
        {
          key: "title2",
          align: "center",
          width: 200,
        },
        {
          key: "name2",
          render: (h, params) => {
            if (params.row.type == "sex") {
              return h(
                "RadioGroup",
                {
                  props: {
                    value: params.row.name2,
                  },
                  on: {
                    "on-change": (val) => {
                      this.table1[params.index].name2 = val;
                    },
                  },
                },
                [
                  h(
                    "Radio",
                    {
                      props: {
                        label: "1",
                      },
                    },
                    "男"
                  ),
                  h(
                    "Radio",
                    {
                      props: {
                        label: "2",
                      },
                    },
                    "女"
                  ),
                ]
              );
            } else if (params.row.type == "age") {
              return h("Input", {
                props: {
                  value: params.row.name2,
                  disabled: true,
                  placeholder: "根据身份证号码自动计算",
                },
              });
            } else if (params.row.type == "orgCode") {
              return h(
                "Select",
                {
                  props: {
                    value: params.row.orgCode,
                    placeholder: "请选择所属社区",
                    filterable: true,
                    transfer: true,
                  },
                  on: {
                    "on-change": (val) => {
                      this.table1[params.index].name2 = val;
                    },
                  },
                },
                (() => {
                  let arr = this.orgCodeList.map((item) => {
                    return h(
                      "Option",
                      {
                        props: {
                          value: item.value,
                        },
                      },
                      item.label
                    );
                  });
                  return arr;
                })()
              );
            } else {
              return h("Input", {
                props: {
                  value: params.row.name2,
                  maxlength: 50,
                },
                on: {
                  "on-change": (e) => {
                    this.table1[params.index].name2 = e.target.value.trim();
                  },
                },
              });
            }

            return h("Input", {
              props: {
                value: params.row.name2,
                maxlength: 50,
              },
              on: {
                "on-change": (e) => {
                  this.table1[params.index].name2 = e.target.value.trim();
                },
              },
            });
          },
        },
        {
          key: "title3",
          align: "center",
          width: 200,
        },
        {
          key: "name3",
          render: (h, params) => {
            if (params.row.type == "radio") {
              return h(
                "RadioGroup",
                {
                  props: {
                    value: params.row.name3,
                  },
                  on: {
                    "on-change": (val) => {
                      this.table1[params.index].name3 = val;
                    },
                  },
                },
                [
                  h(
                    "Radio",
                    {
                      props: {
                        label: "1",
                      },
                    },
                    "有"
                  ),
                  h(
                    "Radio",
                    {
                      props: {
                        label: "2",
                      },
                    },
                    "无"
                  ),
                ]
              );
            } else if (params.row.type1 == "date") {
              return h("DatePicker", {
                props: {
                  value: params.row.name3,
                  disabled: true,
                  placeholder: "根据身份证号码自动计算",
                },
              });
            } else {
              return h("Input", {
                props: {
                  value: params.row.name3,
                  maxlength: 50,
                },
                on: {
                  "on-change": (e) => {
                    this.table1[params.index].name3 = e.target.value.trim();
                  },
                },
              });
            }
          },
        },
      ],
      table1: [
        {
          title1: "申请人姓名：",
          name1: "",
          title2: "性别：",
          name2: "",
          type: "sex",
          title3: "出生日期：",
          name3: "",
          type1: "date",
          cellClassName: {
            title1: "title validate",
            title2: "title validate",
            title3: "title",
          },
        },
        {
          title1: "身份证号码：",
          name1: "",
          title2: "年龄：",
          name2: "",
          type: "age",
          cellClassName: {
            title1: "title validate",
            title2: "title",
          },
        },
        {
          title1: "户籍地址：",
          name1: "",
          title2: "所属社区：",
          name2: "",
          type: "orgCode",
          cellClassName: {
            title1: "title validate",
            title2: "title validate",
          },
        },
        {
          title1: "现居住地：",
          name1: "",
          cellClassName: {
            title1: "title validate",
          },
        },
        {
          title1: "本人电话：",
          name1: "",
          title2: "本人手机：",
          name2: "",
          title3: "社保卡：",
          type: "radio",
          name3: "",
          cellClassName: {
            title1: "title",
            title2: "title validate",
            title3: "title validate",
          },
        },
        {
          title1: "申请代办人：",
          name1: "",
          title2: "与申请人关系：",
          name2: "",
          title3: "联系电话：",
          name3: "",
          cellClassName: {
            title1: "title",
            title2: "title",
            title3: "title",
          },
        },
        {
          title1: "紧急联系人：",
          name1: "",
          title2: "与申请人关系：",
          name2: "",
          title3: "联系电话：",
          name3: "",
          cellClassName: {
            title1: "title",
            title2: "title",
            title3: "title",
          },
        },
      ],
      columns2: [
        {
          key: "title1",
          align: "center",
          width: 200,
        },
        {
          key: "name1",
          render: (h, params) => {
            if (params.row.type == "select") {
              return h(
                "CheckboxGroup",
                {
                  props: {
                    value: params.row.name1,
                  },
                  style: {
                    display: "flex",
                    flexWrap: "wrap",
                  },
                  on: {
                    "on-change": (val) => {
                      this.table2[params.index].name1 = val;
                    },
                  },
                },
                (() => {
                  let arr = this.serveTypeList.map((item) => {
                    return h(
                      "Checkbox",
                      {
                        props: {
                          label: item.value,
                        },
                        style: {
                          width: "20%",
                          margin: "10px 0",
                        },
                      },
                      item.label
                    );
                  });
                  arr.push(
                    h("Input", {
                      props: {
                        value: this.serviceTypeDesc,
                        placeholder: "请输入其他服务类别",
                        maxlength: 50,
                      },
                      style: {
                        display:
                          params.row.name1.indexOf(this.otherServe) > -1
                            ? "inline-block"
                            : "none",
                        width: "80%",
                        margin: "10px 0 20px",
                      },
                      on: {
                        "on-change": (e) => {
                          this.serviceTypeDesc = e.target.value.trim();
                        },
                      },
                    })
                  );
                  return arr;
                })()
              );
            } else if (params.row.type == "beforeRadio") {
              //单选框在前面，文字在后面
              return h(
                "div",
                {
                  style: {
                    lineHeight: "100%",
                  },
                },
                [
                  h(
                    "RadioGroup",
                    {
                      props: {
                        value: params.row.name1,
                      },
                      on: {
                        "on-change": (val) => {
                          this.table2[params.index].name1 = val;
                        },
                      },
                    },
                    [
                      h(
                        "Radio",
                        {
                          props: {
                            label: "1",
                          },
                        },
                        "是"
                      ),
                      h(
                        "Radio",
                        {
                          props: {
                            label: "2",
                          },
                        },
                        "否"
                      ),
                    ]
                  ),
                  h("span", {
                    domProps: {
                      innerText:
                        "居家养老服务资助、送餐补贴、平安通服务资助和其他养老服务资格与评估结果有关",
                    },
                  }),
                  h(
                    "Tooltip",
                    {
                      props: {
                        placement: "top",
                        maxWidth: "500",
                        transfer: true,
                        content:
                          "根据《广州市社区居家养老服务管理办法》(穗府办规〔2016〕 16号）及相关文件要求，申请养老服务、政府购买服务和养老服务补贴的老年人应接受广州市老年人照顾需求等级评估，评估结果作为申请社区居家养老服务资助、送餐补贴、平安通服务资助和其他养老服务的依据。",
                      },
                    },
                    [
                      h("Icon", {
                        props: {
                          type: "md-help-circle",
                        },
                        style: {
                          cursor: "pointer",
                          fontSize: "16px",
                          margin: "0 10px",
                        },
                      }),
                    ]
                  ),
                ]
              );
            } else if (params.row.type == "afterRadio") {
              //文字在前面，单选框在后面
              return h(
                "div",
                {
                  style: {
                    lineHeight: "30px",
                  },
                },
                [
                  h("span", {
                    domProps: {
                      innerText: params.index - 1 + "、" + params.row.itemTitle,
                    },
                  }),
                  h(
                    "Tooltip",
                    {
                      props: {
                        placement: "top",
                        maxWidth: "500",
                        transfer: true,
                        content: params.row.helpDesc,
                      },
                      style: {
                        display: params.row.helpDesc ? "" : "none",
                      },
                    },
                    [
                      h("Icon", {
                        props: {
                          type: "md-help-circle",
                        },
                        style: {
                          cursor: "pointer",
                          fontSize: "16px",
                          margin: "0 10px",
                        },
                      }),
                    ]
                  ),
                  h(
                    "RadioGroup",
                    {
                      props: {
                        value: params.row.result,
                      },
                      on: {
                        "on-change": (val) => {
                          if (val == "2") {
                            this.table2[params.index].name1 = "";
                            this.table2[params.index].path = "";
                            this.table2[params.index].path1 = "";
                            this.table2[params.index].path2 = "";
                          }
                          this.table2[params.index].result = val;
                        },
                      },
                    },
                    [
                      h(
                        "Radio",
                        {
                          props: {
                            label: "1",
                          },
                        },
                        "是"
                      ),
                      h(
                        "Radio",
                        {
                          props: {
                            label: "2",
                          },
                        },
                        "否"
                      ),
                    ]
                  ),
                  h(
                    "div",
                    {
                      style: {
                        marginBottom: "10px",
                        display:
                          params.row.uploadFile == "1" &&
                          params.row.result == "1"
                            ? ""
                            : "none",
                      },
                    },
                    [
                      h("div", [
                        h(
                          "span",
                          {
                            style: {
                              color: "red",
                            },
                          },
                          `您需要上传${params.row.fileDesc}个人资料页`
                        ),
                        h(
                          "span",
                          {
                            style: {
                              color: "#5cadff",
                              cursor: "pointer",
                              marginLeft: "10px",
                            },
                            on: {
                              click: () => {
                                this.seeImgTemp(params.index, params.row);
                              },
                            },
                          },
                          "点击预览模板"
                        ),
                      ]),
                      h("Input", {
                        props: {
                          value: params.row.name1.name,
                          maxlength: 50,
                          disabled: true,
                        },
                        style: {
                          width: "200px",
                        },
                        class: "uploadInput",
                      }),
                      h(
                        "Upload",
                        {
                          props: {
                            action: this.fileAction,
                            name: "file",
                            "before-upload": this.beforeUpload,
                            "on-success": this.successFile,
                            "show-upload-list": false,
                            data: this.uploadData,
                          },
                          style: {
                            display: "inline-block",
                          },
                        },
                        [
                          h(
                            "Button",
                            {
                              props: {
                                icon: "ios-cloud-upload-outline",
                                type: "success",
                              },
                              style: {
                                borderRadius: "0 4px 4px 0",
                              },
                              on: {
                                click: () => {
                                  this.nowOneTwo = "1";
                                  this.uploadIndex = params.index;
                                  this.nowUploadTableName = "申请人现状"; //代表正在进行申请人现状证件上传
                                },
                              },
                            },
                            "浏览文件"
                          ),
                        ]
                      ),
            
                      h("span", {
                        style: {
                          color: "#aaa",
                          marginLeft: "20px",
                        },
                        domProps: {
                          innerHTML: params.row.fileType,
                        },
                      }),

                      h('div',{
                        style: {
                          display: params.row.fileDesc && params.row.fileDesc.split('《').length > 2 && params.row.fileDesc.split('》').length > 2 ? 'block' : 'none',
                          marginTop: '10px'
                        }
                      }),
                      h("Input", {
                        props: {
                          value: params.row.name2.name,
                          maxlength: 50,
                          disabled: true,
                        },
                        style: {
                          width: "200px",
                          display: params.row.fileDesc && params.row.fileDesc.split('《').length > 2 && params.row.fileDesc.split('》').length > 2 ? 'inline-block' : 'none',
                        },
                        class: "uploadInput",
                      }),
                      h(
                        "Upload",
                        {
                          props: {
                            action: this.fileAction,
                            name: "file",
                            "before-upload": this.beforeUpload,
                            "on-success": this.successFile,
                            "show-upload-list": false,
                            data: this.uploadData,
                          },
                          style: {
                            display: params.row.fileDesc && params.row.fileDesc.split('《').length > 2 && params.row.fileDesc.split('》').length > 2 ? 'inline-block' : 'none',
                          },
                        },
                        [
                          h(
                            "Button",
                            {
                              props: {
                                icon: "ios-cloud-upload-outline",
                                type: "success",
                              },
                              style: {
                                borderRadius: "0 4px 4px 0",
                              },
                              on: {
                                click: () => {
                                  this.nowOneTwo = "2";
                                  this.uploadIndex = params.index;
                                  this.nowUploadTableName = "申请人现状"; //代表正在进行申请人现状证件上传
                                },
                              },
                            },
                            "浏览文件"
                          ),
                        ]
                      ),

                      h('div',{}),

                      h(
                        "div",
                        {
                          class: "upload-list",
                          style: {
                            display: params.row.imgSrc1 ? "inline-block" : "none",
                          },
                        },
                        [
                          h("img", {
                            style: {
                              display: "block",
                              width: "100%",
                              height: "100%",
                            },
                            attrs: {
                              src: params.row.imgSrc1,
                            },
                          }),
                          h(
                            "div",
                            {
                              class: "upload-list-cover",
                            },
                            [
                              h("Icon", {
                                props: {
                                  type: "ios-eye-outline",
                                },
                                on: {
                                  click: () => {
                                    this.certificateUrl1 = params.row.imgSrc1;
                                    this.certificateStatus = true;
                                  },
                                },
                              }),
                              h("Icon", {
                                props: {
                                  type: "ios-trash-outline",
                                },
                                on: {
                                  click: () => {
                                    this.table2[params.index].name1 = {};
                                    this.table2[params.index].imgSrc1 = "";
                                    this.table2[params.index].path1 = "";
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      h(
                        "div",
                        {
                          class: "upload-list",
                          style: {
                            display: params.row.imgSrc2 ? "inline-block" : "none",
                          },
                        },
                        [
                          h("img", {
                            style: {
                              display: "block",
                              width: "100%",
                              height: "100%",
                            },
                            attrs: {
                              src: params.row.imgSrc2,
                            },
                          }),
                          h(
                            "div",
                            {
                              class: "upload-list-cover",
                            },
                            [
                              h("Icon", {
                                props: {
                                  type: "ios-eye-outline",
                                },
                                on: {
                                  click: () => {
                                    this.certificateUrl1 = params.row.imgSrc2;
                                    this.certificateStatus = true;
                                  },
                                },
                              }),
                              h("Icon", {
                                props: {
                                  type: "ios-trash-outline",
                                },
                                on: {
                                  click: () => {
                                    this.table2[params.index].name2 = {};
                                    this.table2[params.index].imgSrc2 = "";
                                    this.table2[params.index].path2 = "";
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              );
            } else {
              //备注
              return h("Input", {
                props: {
                  type: "textarea",
                  maxlength: 200,
                },
                style: {
                  margin: "20px 0",
                },
                on: {
                  "on-change": (e) => {
                    this.table2[params.index].name1 = e.target.value.trim();
                  },
                },
              });
            }
          },
        },
      ],
      table2: [
        {
          title1: "申请服务类别：",
          name1: [],
          type: "select",
          cellClassName: {
            title1: "title validate",
          },
        },
        {
          title1: "是否进行照顾需求等级评估：",
          name1: "",
          type: "beforeRadio",
          cellClassName: {
            title1: "title validate",
          },
        },
        {
          title1: "申请人现状：",
          name1: {},
          name2: {},
          type: "afterRadio",
          cellClassName: {
            title1: "title validate",
          },
        },
        {
          title1: "备注：",
          name1: "",
          cellClassName: {
            title1: "title",
          },
        },
      ],
      columns3: [
        {
          key: "title1",
          align: "center",
          width: 200,
        },
        {
          key: "name1",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  lineHeight: "30px",
                  padding: "10px 0",
                },
              },
              [
                h(
                  "div",
                  {
                    style: {
                      display: params.row.text ? "" : "none",
                    },
                  },
                  [
                    h(
                      "span",
                      {
                        style: {
                          color: "red",
                        },
                      },
                      params.row.text
                    ),
                    h(
                      "span",
                      {
                        style: {
                          color: "#5cadff",
                          cursor: "pointer",
                        },
                        on: {
                          click: () => {
                            this.seeImgTemp(params.index, params.row);
                          },
                        },
                      },
                      "点击预览模板"
                    ),
                  ]
                ),
                h("Input", {
                  props: {
                    value: params.row.name1.name,
                    disabled: true,
                    placeholder: params.row.placeholder,
                  },
                  style: {
                    width: "200px",
                  },
                  class: "uploadInput",
                }),
                h(
                  "Upload",
                  {
                    props: {
                      action: this.fileAction,
                      name: "file",
                      "before-upload": this.beforeUpload,
                      "on-success": this.successFile,
                      "show-upload-list": false,
                      data: this.uploadData,
                    },
                    style: {
                      display: "inline-block",
                    },
                  },
                  [
                    h(
                      "Button",
                      {
                        props: {
                          icon: "ios-cloud-upload-outline",
                          type: "success",
                        },
                        style: {
                          borderRadius: "0 4px 4px 0",
                        },
                        on: {
                          click: () => {
                            this.uploadIndex = params.index;
                            this.nowOneTwo = "1";
                            this.nowUploadTableName = "相关证件"; //代表正在进行相关证件上传
                          },
                        },
                      },
                      "浏览文件"
                    ),
                  ]
                ),
                h("span", {
                  style: {
                    color: "#aaa",
                    marginLeft: "20px",
                  },
                  domProps: {
                    innerHTML:
                      "上传文件支持pdf、jpg、png格式，文件大小请勿超过5M",
                  },
                }),
                h('div',{   //空div，为了其他证件的图片换行
                  style: {
                    display: params.row.text ? "none" : "",
                  }
                }),
                h(
                  "div",
                  {
                    style: {
                      marginTop: "10px",
                      display: params.row.text ? "" : "none",
                    },
                  },
                  [
                    h("Input", {
                      props: {
                        value: params.row.name2.name,
                        disabled: true,
                        placeholder: params.row.placeholder2,
                      },
                      style: {
                        width: "200px",
                      },
                      class: "uploadInput",
                    }),
                    h(
                      "Upload",
                      {
                        props: {
                          action: "",
                          name: "file",
                          "before-upload": this.beforeUpload,
                          "on-success": this.successFile,
                          "show-upload-list": false,
                        },
                        style: {
                          display: "inline-block",
                        },
                      },
                      [
                        h(
                          "Button",
                          {
                            props: {
                              icon: "ios-cloud-upload-outline",
                              type: "success",
                            },
                            style: {
                              borderRadius: "0 4px 4px 0",
                            },
                            on: {
                              click: () => {
                                this.uploadIndex = params.index;
                                this.nowOneTwo = "2";
                                this.nowUploadTableName = "相关证件"; //代表正在进行相关证件上传
                              },
                            },
                          },
                          "浏览文件"
                        ),
                      ]
                    ),
                  ]
                ),
                h(
                  "div",
                  {
                    class: "upload-list",
                    style: {
                      display: params.row.imgSrc1 ? "inline-block" : "none",
                    },
                  },
                  [
                    h("img", {
                      style: {
                        display: "block",
                        width: "100%",
                        height: "100%",
                        display: params.row.imgSrc1 ? '' : 'none'
                      },
                      attrs: {
                        src: params.row.imgSrc1,
                      },
                    }),
                    h(
                      "div",
                      {
                        class: "upload-list-cover",
                      },
                      [
                        h("Icon", {
                          props: {
                            type: "ios-eye-outline",
                          },
                          on: {
                            click: () => {
                              this.certificateUrl1 = params.row.imgSrc1;
                              this.certificateStatus = true;
                            },
                          },
                        }),
                        h("Icon", {
                          props: {
                            type: "ios-trash-outline",
                          },
                          on: {
                            click: () => {
                              this.table3[params.index].name1 = {};
                              this.table3[params.index].imgSrc1 = "";
                              this.table3[params.index].path1 = "";
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                h(
                  "div",
                  {
                    class: "upload-list",
                    style: {
                      display: params.row.imgSrc2 ? "inline-block" : "none",
                    },
                  },
                  [
                    h("img", {
                      style: {
                        display: "block",
                        width: "100%",
                        height: "100%",
                        display: params.row.imgSrc2 ? '' : 'none'
                      },
                      attrs: {
                        src: params.row.imgSrc2,
                      },
                    }),
                    h(
                      "div",
                      {
                        class: "upload-list-cover",
                      },
                      [
                        h("Icon", {
                          props: {
                            type: "ios-eye-outline",
                          },
                          on: {
                            click: () => {
                              this.certificateUrl1 = params.row.imgSrc2;
                              this.certificateStatus = true;
                            },
                          },
                        }),
                        h("Icon", {
                          props: {
                            type: "ios-trash-outline",
                          },
                          on: {
                            click: () => {
                              this.table3[params.index].name2 = {};
                              this.table3[params.index].imgSrc2 = "";
                              this.table3[params.index].path2 = "";
                            },
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            );
          },
        },
      ],
      table3: [
        {
          title1: "身份证件：",
          name1: {},
          name2: {},
          text: "您需要上传身份证的正反两面，",
          placeholder: "请上传身份证正面",
          placeholder2: "请上传身份证反面",
          path1: "",
          path2: "",
          cellClassName: {
            title1: "title validate",
          },
        },
        {
          title1: "户口本：",
          name1: {},
          name2: {},
          text: "您需要上传户口本户主页及本人页，",
          placeholder: "请上传户口本户主页",
          placeholder2: "请上传户口本本人页",
          type: "identity",
          path1: "",
          path2: "",
          cellClassName: {
            title1: "title validate",
          },
        },
        {
          title1: "其他证件：",
          name1: {},
          name2: {},
          text: "",
          path1: "",
          cellClassName: {
            title1: "title",
          },
        },
      ],
      //   服务类别列表
      serveTypeList: [],
      otherServe: "", //其他服务类别编码
      //申请人现状
      presentLength: "",
      uploadIndex: "",
      // 文件上传路径
      fileAction: "",
      fileName: "",
      file: "",

      //身份证模板
      tempImgUrl1: "",
      tempImgUrl2: "",
      idNumTitle: "",
      idNumStatus: false,
      nowOneTwo: "", //代表正在上传第一个还是第二个文件

      // 查看证件
      certificateTitle: "",
      certificateStatus: false,
      certificateUrl1: "",
      certificateUrl2: "",
      imgSrc: "",

      // 所属社区列表
      orgCodeList: [],
    };
  },
  methods: {
    // 根据身份证算年龄和出生日期
    getAnalysisIdCard(card, num) {
      if (num == 1) {
        //获取出生日期
        let birth =
          card.substring(6, 10) +
          "-" +
          card.substring(10, 12) +
          "-" +
          card.substring(12, 14);
        return birth;
      }
      if (num == 2) {
        //获取年龄

        var myDate = new Date();

        var month = myDate.getMonth() + 1;

        var day = myDate.getDate();

        var age = myDate.getFullYear() - card.substring(6, 10) - 1;

        if (
          card.substring(10, 12) < month ||
          (card.substring(10, 12) == month && card.substring(12, 14) <= day)
        ) {
          age++;
        }

        return age;
      }
    },
    // 点击预览模板
    seeImgTemp(index, row) {
      if (index == 0) {
        this.tempImgUrl1 = "/images/certi-id-t.jpg";
        this.tempImgUrl2 = "/images/certi-id-b.jpg";
        this.idNumTitle = "身份证正反两面模板";
      } else if (index == 1) {
        this.tempImgUrl1 = "/images/certi-register-t.jpg";
        this.tempImgUrl2 = "/images/certi-register-b.jpg";
        this.idNumTitle = "户口本户主页及本人页模板";
      } else if (index == 5) {
        this.tempImgUrl1 = "/images/certi-laodongmofan.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle = "《劳动模范荣誉证书》模板";
      } else if (index == 7) {
        this.tempImgUrl1 = "/images/certi-wubao.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle = "《农村五保供养证》模板";
      } else if (index == 8) {
        this.tempImgUrl1 = "/images/certi-dibaolingqu.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle =
          "《广州市城镇居民最低生活保障金领取证》和《广州市农村村民最低生活保障金领取证》模板";
      } else if (index == 9) {
        this.tempImgUrl1 = "/images/certi-dishou.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle = "《广州市低收入困难家庭证》模板";
      } else if (index == 10) {
        this.tempImgUrl1 = "/images/certi-youfu.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle = "《优抚对象抚恤补助证》模板";
      } else if (index == 11) {
        this.tempImgUrl1 = "/images/certi-fuzhu.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle = "《计划生育家庭特别扶助制度扶助证》模板";
      } else if (index == 13) {
        this.tempImgUrl1 = "/images/certi-canji.jpg";
        this.tempImgUrl2 = "";
        this.idNumTitle = "《残疾证》模板";
      }
      this.idNumStatus = true;
    },

    // 点击查看证件
    seeImgTemp2(row) {
      let time1 = row.imgSrc1;
      this.certificateUrl1 = row.imgSrc1;
      this.certificateUrl2 = row.imgSrc2;
      this.certificateStatus = true;
    },
    openPromiseModal() {
      if (!this.table1[0].name1) {
        this.$Message.error({
          background: true,
          content: "请填写申请人姓名",
        });
        return;
      }
      if (!this.table1[0].name2) {
        this.$Message.error({
          background: true,
          content: "请选择性别",
        });
        return;
      }
      if (!this.table1[1].name1) {
        this.$Message.error({
          background: true,
          content: "请填写身份证号码",
        });
        return;
      }
      if (!this.table1[2].name1) {
        this.$Message.error({
          background: true,
          content: "请填写户籍地址",
        });
        return;
      }
      if (!this.table1[2].name2) {
        this.$Message.error({
          background: true,
          content: "请选择所属社区",
        });
        return;
      }
      if (!this.table1[3].name1) {
        this.$Message.error({
          background: true,
          content: "请填写现居住地",
        });
        return;
      }
      if (this.table1[4].name2.length != 11) {
        this.$Message.error({
          background: true,
          content: "本人手机号码填写有误，请填写正确的手机号码",
        });
        return;
      }
      if (!this.table1[4].name3) {
        this.$Message.error({
          background: true,
          content: "请选择有无社保卡",
        });
        return;
      }
      if (this.table2[0].name1.length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择申请服务类别",
        });
        return;
      }
      if (
        this.table2[0].name1.indexOf(this.otherServe) > -1 &&
        !this.serviceTypeDesc
      ) {
        this.$Message.error({
          background: true,
          content: "请输入其他服务类别",
        });
        return;
      }

      if (!this.table2[1].name1) {
        this.$Message.error({
          background: true,
          content: "请选择是否进行照顾需求等级评估",
        });
        return;
      }
      for (let k in this.table2) {
        if (k > 1 && k < this.presentLength + 2) {
          if (!this.table2[k].result) {
            this.$Message.error({
              background: true,
              content: "请选择:" + this.table2[k].itemTitle,
            });
            return;
          }
          if (
            this.table2[k].uploadFile == "1" &&
            this.table2[k].result == "1" &&
            !this.table2[k].path1
          ) {
            this.$Message.error({
              background: true,
              content: "请上传" + this.table2[k].fileDesc,
            });
            return;
          }
        }
      }
      if (!this.table3[0].path1) {
        this.$Message.error({
          background: true,
          content: "请上传身份证的正面",
        });
        return;
      }
      if (!this.table3[0].path2) {
        this.$Message.error({
          background: true,
          content: "请上传身份证的反面",
        });
        return;
      }
      if (!this.table3[1].path1) {
        this.$Message.error({
          background: true,
          content: "请上传户口本户主页",
        });
        return;
      }
      if (!this.table3[1].path2) {
        this.$Message.error({
          background: true,
          content: "请上传户口本本人页",
        });
        return;
      }
      this.promiseModal = true;
    },
    // 保存
    save() {
      this.$Message.loading({
        content: "正在保存数据，请稍等...",
        duration: 0,
      });
      // 保存参数
      let params = {
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        levelAssess: this.table2[1].name1,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: this.table1[2].name2,
        presentConditionUserRoList: (() => {
          let arr = [];
          this.table2.map((item, index) => {
            if (index > 1 && index < this.presentLength + 2) {
              arr.push({
                conditionId: item.conditionId,
                result: item.result,
              });
            }
          });
          return arr;
        })(),
        presentConditionFileRoList: (() => {
          let arr = [];
          this.table2.map((item, index) => {
            if (index > 1 && index < this.presentLength + 2) {
              arr.push({
                conditionId: item.conditionId,
                fileUrl: item.path1,
              });
              if(item.path2) {
                arr.push({
                conditionId: item.conditionId,
                fileUrl: item.path2,
              });
              }
            }
          });
          return arr;
        })(),
        serviceType: this.table2[0].name1.join(","),
        serviceTypeDesc: this.serviceTypeDesc,
        userAgentRo: {
          mobile: this.table1[5].name3,
          name: this.table1[5].name1,
          relationship: this.table1[5].name2,
        },
        userContactRo: {
          mobile: this.table1[6].name3,
          name: this.table1[6].name1,
          relationship: this.table1[6].name2,
        },
        userDetailCreateRo: {
          addrCurrent: this.table1[3].name1,
          securityCard: this.table1[4].name3,
        },
        userElderlyCreateRo: {
          birthday: this.table1[0].name3,
          hometown: this.table1[2].name1,
          idNum: this.table1[1].name1,
          name: this.table1[0].name1,
          oemCode: parent.vue.oemInfo.oemCode,
          sex: this.table1[0].name2,
        },
        remark: this.table2[this.table2.length - 1].name1,
        userFileCreateRoList: [
          {
            fileUrl: this.table3[0].path1,
            fileType: "1",
          },
          {
            fileUrl: this.table3[0].path2,
            fileType: "1",
          },
          {
            fileUrl: this.table3[1].path1,
            fileType: "2",
          },
          {
            fileUrl: this.table3[1].path2,
            fileType: "2",
          },
          {
            fileUrl: this.table3[2].path1,
            fileType: "3",
          },
        ],
        userMobileRoList: [
          {
            mobile: this.table1[4].name1,
            type: "2",
          },
          {
            mobile: this.table1[4].name2,
            type: "1",
          },
        ],
      };
      this.$post("/pension/api/pc/elderly/applyRecord/insertRecord", params, {
        "Content-Type": "application/json",
      })
        .then((res) => {
          this.$Message.destroy();
          this.promiseModal = false;
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            this.$emit("saveSuccess");
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.promiseModal = false;
          this.$Message.error({
            background: true,
            content: "保存失败，请重试",
          });
        });
    },
    // 上传文件
    beforeUpload(file) {
      // 根据索引值，把需要上传的file添加到对应的table3项中
      let suffix = file.name.substr(file.name.lastIndexOf("."));
      if (suffix != ".pdf" && suffix != ".jpg" && suffix != ".png") {
        this.$Notice.warning({
          title: "上传文件格式错误！",
          desc:
            "文件 " + file.name + " 上传失败, 请上传pdf,jpg,png格式的文件！",
        });
        return false;
      } else if (file.size / 1024 / 1024 > 5) {
        this.$Notice.error({
          title: "上传失败",
          desc: "文件 " + file.name + " 大小已超出限制，请控制在5M以内",
        });
        return false;
      }
      // 保存查看的图片
      this.imgSrc = window.URL.createObjectURL(file);

      let url =
        window.vue.getProxy()["/pension"].target +
        "/api/common/oss/getWebPolicy";
      this.$get(url).then((res) => {
        if (res.code == 200) {
          let now = this.$core.randomString(8);
          this.uploadData = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.fileAction = res.data.host;
          this.file = file;
          this.fileName = this.uploadData.key;

          // ================
          let formData = new FormData();
          formData.append("name", this.uploadData.name);
          formData.append("host", this.uploadData.host);
          formData.append("key", this.uploadData.key);
          formData.append("policy", this.uploadData.policy);
          formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId);
          formData.append(
            "success_action_status",
            this.uploadData.success_action_status
          );
          formData.append("signature", this.uploadData.signature);
          formData.append("file", this.file);
          this.$.ajax({
            type: "POST",
            contentType: false,
            processData: false,
            url: this.uploadData.host,
            data: formData,
            headers:{openId:window.sessionStorage.getItem("openId"),Authorization:window.sessionStorage.getItem('accessToken')},
            success: () => {
              this.successFile();
            },
          });
        }
      });
      return false;
    },
    // 文件上传成功
    successFile() {
      if (this.nowUploadTableName == "相关证件" && this.nowOneTwo == "1") {
        this.table3[this.uploadIndex].name1 = this.file;
        this.table3[this.uploadIndex].path1 = this.fileAction + this.fileName;
        // 查看本地图片
        this.table3[this.uploadIndex].imgSrc1 = this.imgSrc;
      } else if (
        this.nowUploadTableName == "相关证件" &&
        this.nowOneTwo == "2"
      ) {
        this.table3[this.uploadIndex].name2 = this.file;
        this.table3[this.uploadIndex].path2 = this.fileAction + this.fileName;
        this.table3[this.uploadIndex].imgSrc2 = this.imgSrc;
      } else if(this.nowUploadTableName == "申请人现状" && this.nowOneTwo == "2") {
         this.table2[this.uploadIndex].name2 = this.file;
        this.table2[this.uploadIndex].path2 = this.fileAction + this.fileName;
        this.table2[this.uploadIndex].imgSrc2 = this.imgSrc;
      }else {
        this.table2[this.uploadIndex].name1 = this.file;
        this.table2[this.uploadIndex].path1 = this.fileAction + this.fileName;
        this.table2[this.uploadIndex].imgSrc1 = this.imgSrc;
      }
      this.$Notice.success({
        title: "上传成功",
        desc: "文件 " + this.file.name + " 已成功上传",
      });
    },
    handleSpan({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 1) {
        return [1, 3];
      } else if (rowIndex == 2 && columnIndex == 1) {
        return [1, 3];
      } else if (rowIndex == 3 && columnIndex == 1) {
        return [1, 5];
      }
    },
    handleSpan2({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 2 && columnIndex == 0) {
        return [this.presentLength, 1];
      } else if (
        rowIndex >= 3 &&
        columnIndex == 0 &&
        rowIndex < this.presentLength + 2
      ) {
        return [0, 1];
      }
    },
    handleSpan3({ row, column, rowIndex, columnIndex }) {},
  },
  components: {
    LiefengModal,
  },
  async created() {
    //   获取申请类别
    await this.$get("/pension/api/pc/elderly/applyRecord/getApplyService").then(
      (res) => {
        if (res.code == 200 && res.dataList) {
          this.serveTypeList = res.dataList.map((item) => {
            if (item.dictValue == "其他") {
              this.otherServe = item.dictKey;
            }
            return {
              label: item.dictValue,
              value: item.dictKey,
            };
          });
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }
    );

    //获取申请人现状
    await this.$get(
      "/pension/api/pc/elderly/conditionConfig/queryConfigByOemCode",
      {
        oemCode: parent.vue.oemInfo.oemCode,
      }
    ).then((res) => {
      if (res.code == 200 && res.dataList) {
        this.presentLength = res.dataList.length;
        let arr = res.dataList.map((item) => {
          return {
            title1: "申请人现状：",
            name1: {},
            name2: {},
            result: "",
            path1: "",
            path2: "",
            fileType: item.fileType,
            conditionId: item.conditionId,
            helpDesc: item.helpDesc,
            itemTitle: item.itemTitle,
            uploadFile: item.uploadFile,
            fileDesc: item.fileDesc,
            type: "afterRadio",
            cellClassName: {
              title1: "title validate",
            },
          };
        });
        this.table2.splice(2, 1, ...arr);
      } else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    });
    //获取所属社区
    this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
      staffId: parent.vue.loginInfo.userinfo.id,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
    }).then((res) => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.orgCodeList = res.dataList.map((item) => {
          return {
            label: item.fullName,
            value: item.code,
          };
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "获取社区列表失败！请联系管理员",
        });
      }
    });
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-table {
  .title {
    background-color: rgb(240, 247, 245);
  }
  .validate {
    .ivu-table-cell {
      &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
      }
    }
  }
  .uploadInput {
    /deep/.ivu-input {
      background-color: #fff;
      border-radius: 4px 0 0 4px;
      color: #333;
    }
  }
  /deep/.ivu-input[disabled],
  /deep/.ivu-checkbox-disabled .ivu-checkbox-inner,
  fieldset[disabled] .ivu-input,
  /deep/.ivu-select-disabled .ivu-select-selection,
  /deep/.ivu-radio-disabled .ivu-radio-inner {
    background-color: #fff !important;
    color: #515a6e;
  }
  /deep/.ivu-radio-disabled .ivu-radio-inner,
  /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
    border: 1px solid #515a6e;
  }
  /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,
  /deep/.ivu-checkbox-disabled .ivu-checkbox-inner,
  /deep/.ivu-radio-disabled .ivu-radio-inner:after {
    border-color: #515a6e;
  }
  /deep/.ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #888;
  }

  .upload-list {
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    margin-top: 10px;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
    .upload-list-cover {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
      i {
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
      }
    }
    &:hover {
      .upload-list-cover {
        display: block;
      }
    }
  }
}
</style>